/**
 * Site header
 */
.site-header {
    // border-top: 5px solid $grey-color-dark;
    margin-top:30px;
    border-bottom: 1px solid $grey-color-light;
    min-height: 90px;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    font-size: 36px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    padding-top:10px;
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $grey-color-light;
        line-height: $base-line-height;
        text-decoration: none;

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            // margin-left: 20px;
        }

        &:hover {
            color:#000;
        }

        &[active] {
            color:#000;
        }

        // [Hique]
        .nav-icon {
            display:inline-block;
            font-family: "Glyphicons Regular";
            font-size:26px;
            text-align:center;
            width:33px;
            padding-left:15px;
        }

    }

    @include media-query($on-palm) {
        position: absolute;
        top: 9px;
        right: 30px;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(25% - (#{$spacing-unit} / 2));
    width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(55% - (#{$spacing-unit} / 2));
    width:         calc(55% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}

.title-icon {
    font-family: "Glyphicons Regular";
    font-size:36px;
    padding-right:20px;
    position:relative;
    top:4px;
}


/**
 * Project
 */
.project {
    margin: auto;
    width: 600px;
    margin-top:30px;
    margin-bottom: 50px;
}


/**
 * About
 */
.about-me {
    .nifty {
        margin-top:190px;
    }
}
.portrait {
    float: right;
    width: 200px;
    font-size: 12px;
    img {
        margin-right: 15px;
        margin-bottom: 20px;
        float: right;
    }
}


/**
 * Photos
 */
.see-my-flickr {
    color: black;
}
.photo-set img {
    display: block;
    margin-top:25px;
    margin-bottom:70px;
}

